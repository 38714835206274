@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {

    font-family: 'Raleway', sans-serif;
    background: #172D4E;
  }

  /* Styles pour la barre de défilement */
  ::-webkit-scrollbar {
    min-width: 0.7vw;
    width: 12.5px;
    max-width: 0.7vw;
    /* Largeur de la barre de défilement */
  }

  /* Style de la poignée (thumb) de la barre de défilement */
  ::-webkit-scrollbar-thumb {
    background-color: #3B82F6;
    /* Couleur de la poignée */
    border-radius: 1000px;
    /* Bordure arrondie de la poignée */
    border: 0.15vw solid #1E3B66;
    cursor: pointer;
    transition-duration: 0.25s;
    /* Bordure autour de la poignée */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #2d65be;
    transition-duration: 0.25s;

  }

  /* Style de la piste (track) de la barre de défilement */
  ::-webkit-scrollbar-track {
    margin: 4px;
    border-radius: 1000px;
    background-color: #1E3B66;
    /* Couleur de la piste */
    box-shadow: inset 0 0 5px #1E3B66;
    /* Ombre intérieure sur la piste */
  }

}